const themeConfig = {
  Gruvbox: {
    '--color-primary-50': '#282828',
    '--color-primary-100': '#83a598',
    '--color-primary-200': '#4C566A',
    '--color-primary-300': '#A3BE8C',
    '--color-primary-400': '#BF616A',
    '--color-primary-500': '#ABA59D',
    '--color-primary-600': '#8A8680',
    '--color-primary-700': '#8f3f71',
    '--color-primary-800': '#D08770',
    '--color-primary-900': '#f9f5d7',
  },
  Nord: {
    '--color-primary-50': '#2E3440',
    '--color-primary-100': '#3B4252',
    '--color-primary-200': '#434C5E',
    '--color-primary-300': '#4C566A',
    '--color-primary-400': '#D8DEE9',
    '--color-primary-500': '#E5E9F0',
    '--color-primary-600': '#ECEFF4',
    '--color-primary-700': '#8FBCBB',
    '--color-primary-800': '#88C0D0',
    '--color-primary-900': '#81A1C1',
  },
  AMOLED: {
    '--color-primary-50': '#010101',
    '--color-primary-100': '#121212',
    '--color-primary-200': '#222222',
    '--color-primary-300': '#333333',
    '--color-primary-400': '#444',
    '--color-primary-500': '#696969',
    '--color-primary-600': '#8F8F8F',
    '--color-primary-700': '#B4B4B4',
    '--color-primary-800': '#DADADA',
    '--color-primary-900': '#FFFFFF',
  },
};

export default themeConfig;
