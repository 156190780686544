// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-blog-js": () => import("./../../../src/components/Blog.js" /* webpackChunkName: "component---src-components-blog-js" */),
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-adminboard-js": () => import("./../../../src/pages/app/adminboard.js" /* webpackChunkName: "component---src-pages-app-adminboard-js" */),
  "component---src-pages-app-builder-js": () => import("./../../../src/pages/app/builder.js" /* webpackChunkName: "component---src-pages-app-builder-js" */),
  "component---src-pages-app-dashboard-archive-js": () => import("./../../../src/pages/app/dashboardArchive.js" /* webpackChunkName: "component---src-pages-app-dashboard-archive-js" */),
  "component---src-pages-app-dashboard-js": () => import("./../../../src/pages/app/dashboard.js" /* webpackChunkName: "component---src-pages-app-dashboard-js" */),
  "component---src-pages-app-dashboard-read-only-js": () => import("./../../../src/pages/app/dashboardReadOnly.js" /* webpackChunkName: "component---src-pages-app-dashboard-read-only-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-app-managerboard-js": () => import("./../../../src/pages/app/managerboard.js" /* webpackChunkName: "component---src-pages-app-managerboard-js" */),
  "component---src-pages-app-private-js": () => import("./../../../src/pages/app/private.js" /* webpackChunkName: "component---src-pages-app-private-js" */),
  "component---src-pages-app-settings-js": () => import("./../../../src/pages/app/settings.js" /* webpackChunkName: "component---src-pages-app-settings-js" */),
  "component---src-pages-app-superadminboard-js": () => import("./../../../src/pages/app/superadminboard.js" /* webpackChunkName: "component---src-pages-app-superadminboard-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-r-js": () => import("./../../../src/pages/r.js" /* webpackChunkName: "component---src-pages-r-js" */),
  "component---src-pages-r-view-js": () => import("./../../../src/pages/r/view.js" /* webpackChunkName: "component---src-pages-r-view-js" */)
}

