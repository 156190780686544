import { clone, findIndex, get, has, isUndefined, set, setWith } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import arrayMove from 'array-move';
import i18next from 'i18next';
import demoState from '../data/demoState.json';
import DatabaseContext from './DatabaseContext';
import initialState from '../data/initialState.json';

const ResumeContext = createContext({});

const ResumeProvider = ({ children }) => {
  const { debouncedUpdateResume } = useContext(DatabaseContext);
  const subPosition = 7;
  const memoizedReducer = useCallback(
    (state, { type, payload }) => {
      let newState;
      let index;
      let items;
      let temp;

      switch (type) {
        case 'on_add_item':
          delete payload.value.temp;
          items = get(state, payload.path, []);
          newState = setWith(
            clone(state),
            payload.path,
            [...items, payload.value],
            clone,
          );
          debouncedUpdateResume(newState);
          return newState;

        case 'on_edit_item':
          delete payload.value.temp;
          items = get(state, payload.path);
          index = findIndex(items, ['id', payload.value.id]);
          newState = setWith(
            clone(state),
            `${payload.path}[${index}]`,
            payload.value,
            clone,
          );
          debouncedUpdateResume(newState);
          return newState;

        case 'on_delete_item':
          items = get(state, payload.path);
          index = findIndex(items, ['id', payload.value.id]);
          items.splice(index, 1);
          newState = setWith(clone(state), payload.path, items, clone);
          debouncedUpdateResume(newState);
          return newState;

        case 'on_toggle_use_item':
          items = get(state, payload.path);
          index = findIndex(items, ['id', payload.value.id]);
          if ('isVisible' in items[index]) {
            items[index].isVisible = !items[index].isVisible;
          } else {
            items[index].isVisible = false;
          }
          newState = setWith(clone(state), payload.path, items, clone);
          debouncedUpdateResume(newState);
          return newState;

        case 'on_move_item_up':
          items = get(state, payload.path);
          index = findIndex(items, ['id', payload.value.id]);
          items = arrayMove(items, index, index - 1);
          newState = setWith(clone(state), payload.path, items, clone);
          debouncedUpdateResume(newState);
          return newState;

        case 'on_move_item_down':
          items = get(state, payload.path);
          index = findIndex(items, ['id', payload.value.id]);
          items = arrayMove(items, index, index + 1);
          newState = setWith(clone(state), payload.path, items, clone);
          debouncedUpdateResume(newState);
          return newState;

        case 'change_language':
          newState = set(clone(state), 'metadata.language', payload);
          items = get(
            i18next.getDataByLanguage(payload),
            'translation.builder.sections',
          );
          Object.keys(items).forEach((key) => {
            has(newState, `${key}.heading`) &&
              set(newState, `${key}.heading`, items[key]);
          });
          debouncedUpdateResume(newState);
          return newState;

        case 'reset_layout':
          temp = get(state, 'metadata.template');
          items = get(initialState, `metadata.layout.${temp}`);
          newState = setWith(
            clone(state),
            `metadata.layout.${temp}`,
            items,
            clone,
          );
          debouncedUpdateResume(newState);
          return newState;

        case 'on_input':
          newState = setWith(clone(state), payload.path, payload.value, clone);
          debouncedUpdateResume(newState);
          return newState;

        case 'on_import':
          temp = clone(state);
          newState = payload;
          newState.id = temp.id;
          newState.sId = temp.sId;
          newState.user = temp.user;
          newState.name = temp.name;
          newState.createdAt = temp.createdAt;
          newState.updatedAt = temp.updatedAt;
          debouncedUpdateResume(newState);
          return newState;

        case 'on_import_jsonresume':
          newState = payload;
          newState.id = state.id;
          newState.sId = state.sId;
          newState.user = state.user;
          newState.name = state.name;
          newState.preview = state.preview;
          newState.createdAt = state.createdAt;
          newState.updatedAt = state.updatedAt;
          newState.profile = {
            address: {
              city: get(payload, 'profile.address.city', ''),
              line1: get(payload, 'profile.address.line1', ''),
              line2: get(payload, 'profile.address.line2', ''),
              pincode: get(payload, 'profile.address.pincode', ''),
            },
            email: get(payload, 'profile.email', ''),
            firstName: get(payload, 'profile.firstName', ''),
            lastName: get(payload, 'profile.lastName', ''),
            phone: get(payload, 'profile.phone', ''),
            photograph: get(payload, 'profile.photograph', ''),
            subtitle: get(payload, 'profile.subtitle', ''),
            website: get(payload, 'profile.website', ''),
          };
          newState.social.items = get(payload, 'social.items')
            ? payload.social.items.map((x) => ({
                id: uuidv4(),
                network: x.network,
                username: x.username,
                url: x.url,
              }))
            : [];
          newState.objective.body = get(payload, 'objective.body');
          newState.projects.items = payload.projects.items
            ? payload.projects.items.map((x) => ({
                id: uuidv4(),
                title: x.title,
                link: x.link,
                endDate: x.endDate?.substr(0, subPosition) || '',
                startDate: x.startDate?.substr(0, subPosition) || '',
                summary: x.summary,
              }))
            : [];

          newState.work.items = payload.work.items
            ? payload.work.items.map((x) => ({
                id: uuidv4(),
                company: x.company,
                endDate: x.endDate?.substr(0, subPosition) || '',
                position: x.position,
                startDate: x.startDate?.substr(0, subPosition) || '',
                summary: x.summary,
                website: x.website,
              }))
            : [];
          newState.education.items = payload.education.items
            ? payload.education.items.map((x) => ({
                id: uuidv4(),
                degree: x.degree,
                endDate: x.endDate?.substr(0, subPosition) || '',
                field: x.field,
                gpa: x.gpa || '',
                institution: x.institution,
                startDate: x.startDate?.substr(0, subPosition) || '',
                summary: x.summary,
              }))
            : [];
          newState.awards.items = payload.awards.items
            ? payload.awards.items.map((x) => ({
                id: uuidv4(),
                awarder: x.awarder,
                date: x.date?.substr(0, subPosition) || '',
                summary: x.summary,
                title: x.title,
              }))
            : [];
          newState.skills.items = payload.skills.items
            ? payload.skills.items.map((x) => ({
                id: uuidv4(),
                level: x.level,
                name: x.name,
              }))
            : [];
          newState.hobbies.items = payload.hobbies.items
            ? payload.hobbies.items.map((x) => ({
                id: uuidv4(),
                name: x.name,
              }))
            : [];
          newState.languages.items = payload.languages.items
            ? payload.languages.items.map((x) => ({
                id: uuidv4(),
                name: x.name,
                fluency: x.fluency,
              }))
            : [];
          newState.certifications.items = payload.certifications.items
            ? payload.certifications.items.map((x) => ({
                id: uuidv4(),
                issuer: x.issuer,
                summary: x.summary,
                title: x.title,
                date: x.date?.substr(0, subPosition) || '',
              }))
            : [];
          newState.references.items = payload.references.items
            ? payload.references.items.map((x) => ({
                id: uuidv4(),
                name: x.name,
                email: x.email,
                phone: x.phone,
                position: x.position,
                summary: x.summary,
              }))
            : [];
          debouncedUpdateResume(newState);
          return newState;

        case 'set_data':
          newState = payload;
          // this code just refresh the skills value(for example: from "5 years" to "5") when resumes are opened
          // after all of our co-workers resumes pages are opened, this code(line 241~324) should be deleted.
          newState.skills.items = payload.skills.items
            ? payload.skills.items.map((x) => ({
                id: x.id,
                level: x.level.toString().trim().split(' ')[0],
                name: x.name,
              }))
            : [];

          newState.projects.items = payload.projects.items
            ? payload.projects.items.map((x) => ({
                id: uuidv4(),
                title: x.title,
                link: x.link,
                endDate: x.endDate?.substr(0, subPosition) || '',
                startDate: x.startDate?.substr(0, subPosition) || '',
                summary: x.summary,
              }))
            : [];

          newState.work.items = payload.work.items
            ? payload.work.items.map((x) => ({
                id: uuidv4(),
                company: x.company,
                endDate: x.endDate?.substr(0, subPosition) || '',
                position: x.position,
                startDate: x.startDate?.substr(0, subPosition) || '',
                summary: x.summary,
                website: x.website,
              }))
            : [];
          newState.education.items = payload.education.items
            ? payload.education.items.map((x) => ({
                id: uuidv4(),
                degree: x.degree,
                endDate: x.endDate?.substr(0, subPosition) || '',
                field: x.field,
                gpa: x.gpa || '',
                institution: x.institution,
                startDate: x.startDate?.substr(0, subPosition) || '',
                summary: x.summary,
              }))
            : [];
          newState.awards.items = payload.awards.items
            ? payload.awards.items.map((x) => ({
                id: uuidv4(),
                awarder: x.awarder,
                date: x.date?.substr(0, subPosition) || '',
                summary: x.summary,
                title: x.title,
              }))
            : [];
          newState.certifications.items = payload.certifications.items
            ? payload.certifications.items.map((x) => ({
                id: uuidv4(),
                issuer: x.issuer,
                summary: x.summary,
                title: x.title,
                date: x.date?.substr(0, subPosition) || '',
              }))
            : [];

          debouncedUpdateResume(newState);
          return newState;

        case 'reset_data':
          newState = initialState;
          newState.id = state.id;
          newState.sId = state.sId;
          newState.user = state.user;
          newState.name = state.name;
          newState.preview = state.preview;
          newState.createdAt = state.createdAt;
          newState.updatedAt = state.updatedAt;
          debouncedUpdateResume(newState);
          return newState;

        case 'load_demo_data':
          newState = demoState;
          newState.id = state.id;
          newState.sId = state.sId;
          newState.user = state.user;
          newState.name = state.name;
          newState.preview = state.preview;
          newState.createdAt = state.createdAt;
          newState.updatedAt = state.updatedAt;
          debouncedUpdateResume(newState);
          return newState;

        default:
          throw new Error();
      }
    },
    [debouncedUpdateResume],
  );

  const [state, dispatch] = useReducer(memoizedReducer, initialState);

  return (
    <ResumeContext.Provider value={{ state, dispatch }}>
      {children}
    </ResumeContext.Provider>
  );
};

const useSelector = (path, fallback) => {
  const { state } = useContext(ResumeContext);
  let value = get(state, path);

  if (isUndefined(value)) {
    value = isUndefined(fallback) ? state : fallback;
  }

  return value;
};

const useDispatch = () => {
  const { dispatch } = useContext(ResumeContext);
  return dispatch;
};

const memoizedProvider = memo(ResumeProvider);

export {
  ResumeContext,
  memoizedProvider as ResumeProvider,
  useSelector,
  useDispatch,
};
