import { debounce } from 'lodash';
import React, { createContext, memo, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ShortUniqueId from 'short-unique-id';
import firebase from 'gatsby-plugin-firebase';
import { toast } from 'react-toastify';
import UserContext from './UserContext';
import { getUnsplashPhoto } from '../utils';
import initialState from '../data/initialState.json';

const DEBOUNCE_WAIT_TIME = 1000;

const defaultState = {
  isUpdating: false,
  createResume: async () => {},
  duplicateResume: async () => {},
  deleteResume: () => {},
  getResume: async () => {},
  getResumeId: async () => {},
  getCompany: async () => {},
  getResumes: async () => {},
  updateResume: async () => {},
  updateCompanyInfo: async () => {},
  createCompanyInfo: async () => {},
  debouncedUpdateResume: async () => {},
};

const DatabaseContext = createContext(defaultState);

const DatabaseProvider = ({ children }) => {
  const dictionary = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  const shortId = new ShortUniqueId({ dictionary });

  const [isUpdating, setUpdating] = useState(false);
  const { user } = useContext(UserContext);

  const getResume = async (id) => {
    try {
      const snapshot = await firebase
        .database()
        .ref(`resumes/${id}`)
        .once('value');
      return snapshot.val();
    } catch (error) {
      return null;
    }
  };

  const getResumeId = async (sId) => {
    try {
      const snapshot = await firebase
        .database()
        .ref(`keyPairs/${sId}`)
        .once('value');
      return snapshot.val().id;
    } catch (error) {
      return null;
    }
  };

  const getCompany = async (id) => {
    try {
      const snapshot = await firebase
        .database()
        .ref(`companies/${id}`)
        .once('value');
      return snapshot.val();
    } catch (error) {
      return null;
    }
  };

  const createResume = async ({ name }) => {
    const id = uuidv4();
    const sId = shortId();
    const preview = await getUnsplashPhoto();
    const createdAt = firebase.database.ServerValue.TIMESTAMP;

    let firstName;
    let lastName;

    if (user && !user.isAnonymous) {
      [firstName, lastName] = user.displayName.split(' ');
    }

    const resume = {
      ...initialState,
      id,
      sId,
      name,
      companyId: user.managerCompanyId || user.adminCompanyId || '',
      user: user.uid,
      preview,
      profile: {
        ...initialState.profile,
        firstName: firstName || '',
        lastName: lastName || '',
      },
      createdAt,
      updatedAt: createdAt,
    };

    const keyPair = { id };
    firebase.database().ref(`keyPairs/${sId}`).set(keyPair);
    firebase.database().ref(`resumes/${id}`).set(resume);
    // create success toast
    toast.success('Resume created successfully');
  };

  const duplicateResume = async (originalResume) => {
    const id = uuidv4();
    const sId = shortId();
    const preview = await getUnsplashPhoto();
    const createdAt = firebase.database.ServerValue.TIMESTAMP;

    const resume = {
      ...originalResume,
      id,
      sId,
      name: `${originalResume.name} Copy`,
      preview,
      createdAt,
      updatedAt: createdAt,
    };

    const keyPair = { id };
    firebase.database().ref(`keyPairs/${sId}`).set(keyPair);
    firebase.database().ref(`resumes/${id}`).set(resume);
  };

  const updateResume = async (resume) => {
    setUpdating(true);
    try {
      await firebase
        .database()
        .ref(`resumes/${resume.id}`)
        .update({
          ...resume,
          updatedAt: firebase.database.ServerValue.TIMESTAMP,
        });
    } catch (error) {
      toast.error('An error occurred updating');
    }
    setUpdating(false);
  };

  const debouncedUpdateResume = debounce(updateResume, DEBOUNCE_WAIT_TIME);

  const deleteResume = async (id) => {
    const resume = await getResume(id);
    if (resume) {
      const { sId } = resume;
      if (sId) {
        await firebase.database().ref(`/keyPairs/${sId}`).remove();
      }
      await firebase.database().ref(`/resumes/${id}`).remove();
    }
  };

  const updateUserInfo = async (userInfo) => {
    await firebase.database().ref(`users/${userInfo.uid}`).set(userInfo);
  };

  const getUserInfo = async (email) =>
    new Promise((resolve, reject) => {
      firebase
        .database()
        .ref(`users`)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            const data = snapshot.val();
            Object.keys(data).forEach((key) => {
              if (data[key].email === email && !data[key].temp) {
                const userInfo = data[key];
                resolve(userInfo);
              }
            });
          }
          reject(new Error('User do not exist'));
        });
    });

  const initNewUser = async (email) => {
    const userInfo = await getUserInfo(email);
    firebase
      .database()
      .ref(`users`)
      .orderByChild('temp')
      .equalTo(true)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          Object.keys(data).forEach((key) => {
            if (data[key].email === email) {
              userInfo.managerCompanyId = data[key].managerCompanyId;
              updateUserInfo(userInfo);
              firebase.database().ref(`users/${data[key].uid}`).set(null);
            }
          });
        }
      });
  };

  const checkCompanyExist = async (orgNumber) =>
    new Promise((resolve, reject) => {
      firebase
        .database()
        .ref(`companies`)
        .orderByChild('orgNumber')
        .equalTo(orgNumber)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            reject(new Error('Company already exists'));
          } else {
            resolve('ok');
          }
        });
    });

  const createCompanyInfo = async (companyInfo) => {
    const userInfo = await getUserInfo(companyInfo.adminEmail);
    if (userInfo) {
      await checkCompanyExist(companyInfo.orgNumber);
      userInfo.adminCompanyId = companyInfo.id;
      await firebase.database().ref(`users/${userInfo.uid}`).set(userInfo);
      await firebase
        .database()
        .ref(`companies/${companyInfo.id}`)
        .set(companyInfo);
    }
  };

  const updateCompanyInfo = async (companyInfo) => {
    const userInfo = await getUserInfo(companyInfo.adminEmail);
    if (userInfo) {
      userInfo.adminCompanyId = companyInfo.id;
      await firebase.database().ref(`users/${userInfo.uid}`).set(userInfo);
      await firebase
        .database()
        .ref(`companies/${companyInfo.id}`)
        .update({ ...companyInfo });
    }
  };

  const deleteCompanyById = async (cid) => {
    await firebase.database().ref(`companies/${cid}`).remove();
  };

  return (
    <DatabaseContext.Provider
      value={{
        isUpdating,
        getResume,
        getResumeId,
        getCompany,
        createResume,
        duplicateResume,
        updateResume,
        deleteResume,
        updateUserInfo,
        initNewUser,
        getUserInfo,
        updateCompanyInfo,
        deleteCompanyById,
        createCompanyInfo,
        debouncedUpdateResume,
      }}
    >
      {children}
    </DatabaseContext.Provider>
  );
};

export default DatabaseContext;

const memoizedProvider = memo(DatabaseProvider);

export {
  memoizedProvider as DatabaseProvider,
  DEBOUNCE_WAIT_TIME as DebounceWaitTime,
};
