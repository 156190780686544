const ModalEvents = {
  AUTH_MODAL: 'auth_modal',
  CREATE_RESUME_MODAL: 'create_resume_modal',
  SOCIAL_MODAL: 'social_modal',
  WORK_MODAL: 'work_modal',
  EDUCATION_MODAL: 'education_modal',
  PROJECT_MODAL: 'project_modal',
  AWARD_MODAL: 'award_modal',
  CERTIFICATION_MODAL: 'certification_modal',
  SKILL_MODAL: 'skill_modal',
  HOBBY_MODAL: 'hobby_modal',
  LANGUAGE_MODAL: 'language_modal',
  REFERENCE_MODAL: 'reference_modal',
  IMPORT_MODAL: 'import_modal',
  EXPORT_MODAL: 'export_modal',
  ADD_MANAGER_AUTH_MODAL: 'add_manager_auth_modal',
  UPDATE_USER_PERMISSION_OF_VISIBLE_TO_ADMIN_MENU_MODAL:
    'user_permisson_of_visible_to_admin_menu_modal',
  UPDATE_COMPANY_INFO_MODAL: 'update_company_info_modal',
  ADD_COMPANY_MODAL: 'add_company_modal',
};

export default ModalEvents;
